.menubar-wrapper {
  position: relative;
}
.menubar {
  display: flex;
  align-items: center;
  padding: 0 16px;
  button {
    height: 44px;
    width: 44px;
    border-right: 0;
    border-radius: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    &:first-child {
      border-radius: 4px 0 0 4px;
    }
    &:last-child {
      border-right: 1px solid #ccc;
      border-radius: 0 4px 4px 0;
    }
    &.is-active {
      background: #444;
      color: #fff;
      border-color: transparent;
    }
  }
}
.image-dialog {
  position: absolute;
  top: 0;
  width: 100%;
  height: 120px;
  background: #222;
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
  p {
    color: #fff;
    text-align: center;
    margin-bottom: 16px;
  }
  button {
    height: 44px;
    padding: 0 16px;
    margin-right: 16px;
  }
}

.tiptap {
  min-height: calc(100vh - 60px);
  padding: 16px;
  outline: none;

  > * + * {
    margin-top: 0.75em;
  }

  ul,
  ol {
    padding: 0 1rem;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    line-height: 1.1;
  }

  code {
    background-color: rgba(#616161, 0.1);
    color: #616161;
  }

  pre {
    background: #0d0d0d;
    color: #fff;
    font-family: "JetBrainsMono", monospace;
    padding: 0.75rem 1rem;
    border-radius: 0.5rem;

    code {
      color: inherit;
      padding: 0;
      background: none;
      font-size: 0.8rem;
    }
  }

  mark {
    background-color: #faf594;
  }

  img {
    max-width: 100%;
    height: auto;
  }

  blockquote {
    padding-left: 1rem;
    border-left: 2px solid rgba(#0d0d0d, 0.1);
  }

  hr {
    border: none;
    border-top: 2px solid rgba(#0d0d0d, 0.1);
    margin: 2rem 0;
  }
}
